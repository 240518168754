import React from "react"
import styled, { css } from "styled-components"
import media from "./media"

const Input = ({ type, name, label, values, required, onChange }) => {
  const [value, setValue] = React.useState("")
  const [focused, setFocused] = React.useState(false)
  return (
    <StyledInput populated={value.length > 0 || focused}>
      <label htmlFor={name} className="label" aria-label={label}>
        {label}
      </label>

      {type === "select" ? (
        <>
          <select
            name={name}
            className="field"
            required={required}
            onChange={e => setValue(e.target.value)}
            onFocus={e => setFocused(true)}
            onBlur={e => setFocused(false)}
            aria-label={label}
          >
            <option value="" aria-label="Please select and option"></option>
            {values.map(({ key, value }) => (
              <option value={key} index={key}>
                {value}
              </option>
            ))}
          </select>
          <div className="arrow" />
        </>
      ) : (
        <input
          type={type}
          name={name}
          className="field"
          required={required}
          onChange={e => setValue(e.target.value)}
          onFocus={e => setFocused(true)}
          onBlur={e => setFocused(false)}
          aria-label={label}
        />
      )}
    </StyledInput>
  )
}
export default Input

const StyledInput = styled.div`
  width: 100%;
  height: 2.5em;
  display: block;
  position: relative;
  color: currentColor;
  font-size: 14px;
  ${media.down("m")} {
    font-size: 12px;
  }

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: currentColor;
  }

  .field {
    display: block;
    width: 100%;
    height: 1.2em !important;
    border: none;
    position: relative;
    box-sizing: content-box;
    line-height: 1.2em;
    z-index: 2;
    background-color: transparent;
    border-radius: 0;
    letter-spacing: 0.2em;
    outline: none;
    margin: 0;
    top: 1em;
    color: currentColor;
    appearance: none;
    option {
      color: var(--black);
    }
  }
  .field:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .label {
    position: absolute;
    height: 1em;
    display: block;
    top: 1em;
    left: 0;
    transition: all 0.05s;
    color: currentColor;
    line-height: 1em;
    z-index: 1;
    box-sizing: content-box;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    ${({ populated }) =>
      populated &&
      css`
        font-size: 0.7em;
        line-height: 1;
        top: 0;
        opacity: 0.8;
      `}
  }

  &:focus {
    font-size: 0.7em;
    line-height: 1;
    top: 0;
    opacity: 0.8;
  }

  .arrow {
    position: absolute;
    width: 0.65em;
    height: 0.65em;
    bottom: 0.75em;
    right: 0.2em;
    transform: rotate(-45deg);
    z-index: 1;
    border-bottom: 1px solid currentColor;
    border-left: 1px solid currentColor;
  }
`
