import React from "react"
import styled from "styled-components"

import media from "./media"

const Footer = () => (
  <Wrapper>
    <span>Fully Activated {new Date().getFullYear()}</span> &nbsp;{" "}
    <a
      href="mailto:clinic@fullyactivated.com.au"
      title="clinic@fullyactivated.com.au"
      rel="noreferrer"
      target="_blank"
    >
      clinic@fullyactivated.com.au
    </a>{" "}
    &nbsp; <a href="tel:61413078633">+61 413 078 633</a>
  </Wrapper>
)

export default Footer

const Wrapper = styled.footer`
  border-top: 2px solid var(--blue);
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-top: 2em;
  ${media.down("m")} {
    padding: 1.5em 4%;
    display: flex;
    flex-direction: column;
  }
`
