import mediaHelper from "styled-media-helper"

const media = mediaHelper({
  s: 600,
  m: 800,
  l: 1050,
  xl: 1200,
})

export default media
