import React, { useContext } from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { darken } from "polished"

import { GolbalStateContext } from "../context"

import media from "./media"

import LogoIcon from "../icons/Fully_Activated_Logo.svg"
import FacebookIcon from "../icons/facebook-icon.svg"
import InstagramIcon from "../icons/instagram-icon.svg"

import Nav from "./nav"
import Hamburger from "./hamburger"
import Button from "./button"

const Header = ({ solid }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setGlobalState] = useContext(GolbalStateContext)

  return (
    <Wrapper solid={solid}>
      <div className="super">
        <a
          href="mailto:clinic@fullyactivated.com.au"
          title="clinic@fullyactivated.com.au"
          rel="noreferrer"
          target="_blank"
        >
          clinic@fullyactivated.com.au
        </a>{" "}
        <span>&nbsp; | &nbsp;</span>{" "}
        <a href="tel:61413078633">+61 413 078 633</a>
      </div>

      <div className="body">
        <div className="logo">
          <Link to="/" title="Home">
            <LogoIcon />
          </Link>
        </div>

        <div className="right">
          <Nav />
          <a
            href="https://www.facebook.com/fullyactivatedosteopathy"
            title="Facebook"
            target="_blank"
            rel="noreferrer"
            className="socialIcon"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/fullyactivatedosteopathy/?hl=en"
            title="Instagram"
            target="_blank"
            rel="noreferrer"
            className="socialIcon"
          >
            <InstagramIcon />
          </a>
          <Button
            onClick={() =>
              setGlobalState(gs => ({
                ...gs,
                enquireOpen: true,
              }))
            }
          >
            Enquire
          </Button>
          <div className="hamburger">
            <Hamburger />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

Header.propTypes = {
  solid: Proptypes.bool,
}

export default Header

const Wrapper = styled.header`
  text-align: center;
  position: absolute;
  width: 100%;
  height: 160px;
  padding: 0;

  ${({ solid }) =>
    solid &&
    css`
      background-color: var(--white);
      position: fixed;
      z-index: 1001;
    `}

  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 2em;
    ${({ solid }) =>
      solid &&
      css`
        padding-bottom: 0;
      `}
    ${media.down("m")} {
      z-index: 1000;
    }
  }

  .super {
    height: 2em;
    line-height: 2em;
    background-color: var(--grey);
    color: var(--white);
    text-align: right;
    width: 100%;
    font-size: 0.75em;
    padding: 0 var(--globalPadding);
    ${media.down("m")} {
      display: none;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.2em var(--globalPadding);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0)
    );
  }

  .logo {
    position: relative;
    width: 180px;
    &:after {
      content: "";
      display: block;
      padding-bottom: 54.37117831763345%;
    }
    ${media.down("m")} {
      width: 100px;
    }

    ${({ solid }) =>
      solid &&
      css`
        width: 140px;
        ${media.down("m")} {
          width: 100px;
        }
      `}
  }

  .contact {
    text-align: right;
    align-self: flex-start;
    font-weight: bold;
    color: ${darken(`30%`, "#7f7d7c")};
  }

  .socialIcon {
    height: 1.5em;
    width: 1.5em;
    position: relative;
    display: none;
    color: var(--blue);
    vertical-align: middle;
    margin: 0 1em;
    ${media.up("xl")} {
      display: inline-block;
    }
    &:hover {
      color: var(--darkBlue);
    }
  }

  .socialIcon + .socialIcon {
    margin-left: -0.5em;
  }

  ${({ solid }) =>
    solid &&
    css`
      border-bottom: 1px solid var(--grey);
      height: calc(var(--headerHeight) - 20px);
    `}

  ${Button} {
    display: none;
    ${media.up("xl")} {
      display: inline-block;
    }
  }

  .hamburger {
    display: none;
    ${media.down("m")} {
      display: block;
      position: relative;
      z-index: 1001;
    }
  }
`
