import React from "react"
import styled, { css } from "styled-components"

import { GolbalStateContext } from "../context"

import media from "./media"
import Form from "./form"

const Register = () => {
  const [globalState, setGlobalState] = React.useContext(GolbalStateContext)

  return (
    <Wrapper open={globalState.enquireOpen}>
      <div
        role="button"
        tabIndex={0}
        className="close"
        aria-label="Close Button"
        onClick={() =>
          setGlobalState(gs => ({
            ...gs,
            enquireOpen: false,
          }))
        }
        onKeyPress={() =>
          setGlobalState(gs => ({
            ...gs,
            enquireOpen: false,
          }))
        }
      />
      <div className="header" />

      <Form dark name="enquiry" enquiry />
      <div className="contact">
        <h4 className="heading">CONTACT</h4>
        <p>
          <a
            href="mailto:clinic@fullyactivated.com.au"
            title="clinic@fullyactivated.com.au"
          >
            clinic@fullyactivated.com.au
          </a>
          <br />
          <a href="tel:61413078633">+61 413 078 633</a>
        </p>
        <div className="notes">
          Please note: Appointment is required. No walk-ins.
        </div>
      </div>

      <div className="thankyou">
        <h3>
          THANK YOU
          <br />
          FOR SUBMITTING
          <br />
          YOUR DETAILS.
        </h3>
        <p>WE WILL BE IN CONTACT WITH YOU SOON.</p>
      </div>
    </Wrapper>
  )
}

export default Register

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  color: var(--white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 2000;
  background-color: var(--blue);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--globalPadding);
  ${media.down("m")} {
    padding-left: 5%;
    padding-right: 5%;
    justify-content: flex-start;
  }
  ${media.down("s")} {
    overflow: auto;
  }

  ${props =>
    props.open &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 2rem;
    width: 2rem;
    color: var(--white);
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      background-color: currentColor;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      background-color: currentColor;
    }
  }

  .header {
    width: 100%;
    text-align: center;
  }

  .heading {
    font-family: var(--trim);
    font-weight: 400;
    font-size: 2em;
    text-align: center;
    margin-top: 0;
    ${media.down("m")} {
      font-size: 1.3em;
      margin-bottom: 0.5em;
    }
  }

  .form {
    text-align: center;
    margin-bottom: 3rem;
  }

  .fields {
    width: 690px;
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    ${media.down("m")} {
      width: 100%;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
  }

  .field {
    width: 100%;
    height: 45px;
    margin-bottom: 1em;

    ${media.down("m")} {
      height: 40px;
      width: 100%;
      margin-bottom: 0.1em;
    }
  }

  .thankyou {
    display: none;
    text-align: center;
    h3 {
      font-family: var(--flama);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2rem;
      letter-spacing: 0.2em;
    }
  }

  .submitButton {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    border: 1px solid currentColor;
    padding: 0.4em 2em;
    cursor: pointer;
    color: var(--white);
    transition: all 0.2s;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 0.3em;
    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }

  .footer {
    margin-top: 3em;
  }

  .notes {
    margin-top: 3rem;
    font-size: 1em;
    text-align: center;
    font-style: italic;
    ${media.down("m")} {
      margin-top: 1em;
    }
  }

  .contact {
    text-align: center;
    font-size: 1.2em;
    line-height: 1.5em;
    a {
      color: inherit;
      text-decoration: none;
    }

    ${media.down("m")} {
      font-size: 0.9em;
    }
  }
`
