import styled, { css } from "styled-components"

const Button = styled.button`
  display: inline-block;
  border: 1px solid var(--blue);
  padding: 0.5em 2em;
  color: var(--white);
  background-color: var(--blue);
  font-family: var(--trim);
  text-decoration: none;
  font-weight: normal;

  &:hover {
    background-color: transparent;
    color: var(--blue);
  }

  ${({ light }) =>
    light &&
    css`
      border-color: var(--white);
      &:hover {
        background-color: var(--white);
        color: var(--blue);
      }
    `}
`

export default Button
