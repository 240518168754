import React from "react"
import styled, { css } from "styled-components"

import { GolbalStateContext } from "../context"

const Hamburger = () => {
  const [globalState, setGlobalState] = React.useContext(GolbalStateContext)

  const { menuOpen } = globalState

  return (
    <Wrapper
      onClick={() =>
        setGlobalState(globalState => ({
          ...globalState,
          menuOpen: !globalState.menuOpen,
        }))
      }
      menuOpen={menuOpen}
    >
      <Line menuOpen={menuOpen} />
      <Line menuOpen={menuOpen} />
      <Line menuOpen={menuOpen} />
    </Wrapper>
  )
}

export default Hamburger

const Wrapper = styled.div`
  width: 26px;
  height: 20px;
  position: relative;
  cursor: pointer;
`

const Line = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: var(--blue);
  transition: all 0.3s linear;

  &:first-child {
    top: 0;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
  }

  &:nth-child(2) {
    transition: transform 0.2s linear 0;
  }

  &:last-child {
    top: 100%;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0;
  }

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      background-color: var(--white);
      top: 50% !important;
      transform: rotate(45deg);

      &:first-child {
        transition: top 0.2s linear, transform 0.2s linear 0.2s;
      }

      &:nth-child(2) {
        transition: transform 0.2s linear 0.2s;
      }

      &:last-child {
        transform: rotate(-45deg);
        transition: top 0.2s linear, transform 0.2s linear 0.2s;
      }
    `}
`
