import React from "react"
import styled, { css } from "styled-components"

import media from "./media"

import Input from "./input"
import Button from "./button"

const fields = [
  {
    name: "fullname",
    label: "Name",
    required: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    required: true,
  },
  {
    name: "email",
    label: "Email Address",
    type: "email",
    required: true,
  },
  {
    name: "enquiry",
    label: "Enquiry",
  },
]

const Form = ({ name, heading, subHeading, dark, enquiry }) => (
  <Wrapper
    action="/thank-you/"
    name={name}
    method="post"
    dark={dark}
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    netlify
  >
    <input type="hidden" name="form-name" value={name} />
    <h4 className="heading">
      {heading ? heading : "Want to get in contact with us?"}
    </h4>
    <p>
      {subHeading
        ? subHeading
        : "Fill in the form below and we will be in touch with you with as soon as possible"}
    </p>

    <div className="fields">
      {fields
        .filter(f => !(f.name === "enquiry" && !enquiry))
        .map(f => (
          <div key={f.name} className="field">
            <Input {...f} />
          </div>
        ))}
    </div>
    <Button as="input" type="submit" value="Submit" light />
  </Wrapper>
)

export default Form

const Wrapper = styled.form`
  text-align: center;
  margin-bottom: 3rem;
  background-color: var(--blue);
  color: var(--white);
  padding: 2em;

  ${media.down("m")} {
    padding-top: 4em;
  }

  body.submit-successful & {
    display: none;
  }

  .heading {
    font-family: var(--trim);
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    margin-top: 0;
    ${media.down("m")} {
      font-size: 1.5rem;
    }
  }

  .fields {
    width: 690px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    position: relative;
    margin: 0 auto;
    ${media.down("m")} {
      width: 100%;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
    ${({ dark }) =>
      dark &&
      css`
        width: 100%;
      `};
  }

  .field {
    width: 100%;
    height: 45px;
    margin-bottom: 1em;

    ${media.down("m")} {
      height: 40px;
      width: 100%;
      margin-bottom: 0.1em;
    }
  }

  .thankyou {
    display: none;
    text-align: center;
    h3 {
      font-family: var(--flama);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2rem;
      letter-spacing: 0.2em;
    }
    /* body.submit-successful &
			display block */
  }

  .submitButton {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    border: 1px solid currentColor;
    padding: 0.4em 2em;
    transition: all 0.2s;
    cursor: pointer;
    color: var(--white);
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 0.3em;
  }

  .submitButton:hover {
    color: var(--blue);
    background-color: var(--white);
  }

  .footer {
    margin-top: 3em;
  }

  .notes {
    margin-top: 3rem;
    font-size: 1em;
    text-align: center;
    font-style: italic;
  }

  .contact {
    text-align: center;
    font-size: 1.2em;
    line-height: 1.5em;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  ${({ dark }) =>
    dark &&
    css`
    background-color var(--blue);
  `}
`
